@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,300;0,400;1,500&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'Prompt', sans-serif;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.text-bold {
  font-family: 'Prompt', sans-serif;
  font-weight: 500;
}